<template>
  <div class="app-container">
    <searchHeader
      v-p="['externaladmin:financialManagement:withdrawManagement:search']"
      @refreshPage1="refreshPage"
      :query="query"
      @search="search"
    />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable height="auto" :selection="false" :cols="cols" :infoData="data">
      <template #applyTimeSlot="{ scoped: row }">{{ parseTime(row[row.prop]) }}</template>
      <template #withdrawSlot="{ scoped: row }">{{ getCurState(row) }}</template>
      <el-table-column align="center" label="操作">
        <template slot-scope="{ row }">
          <color-text-btn
            v-if="row.approveStatus == 0"
            v-p="['externaladmin:financialManagement:withdrawManagement:review']"
            @click="onReview(row)"
            >审核</color-text-btn
          >
        </template>
      </el-table-column>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <reviewDialog :sup_this="this" :reviewData="reviewData" :visible.sync="visible" />
  </div>
</template>

<script>
import searchHeader from './module/header.vue'
import reviewDialog from './module/reviewDialog.vue'
import { initDataMixin } from '@/mixins'
import { withdrawManageCols as cols } from './module/cols'
import { parseTime } from '@/utils'
import ColorTextBtn from '@/components/base/colorTextBtn.vue'
export default {
  mixins: [initDataMixin],
  components: { searchHeader, ColorTextBtn, reviewDialog },
  data() {
    return {
      cols,
      sup_this: this,
      url: '/externaladmin/financeService/paymentAccount/getWithdrawApplyRecordList',
      visible: false,
      mergeData: {
        orderItems: [
          {
            column: 'apply_time',
            asc: false
          }
        ]
      },
      reviewData: {},
      query: {
        userName: '',
        userLevel: null,
        createTime: null
      }
    }
  },
  methods: {
    parseTime,
    getCurState({ approveStatus }) {
      let state
      switch (approveStatus) {
        case 0:
          state = '未审核'
          break
        case 1:
          state = '已通过'
          break
        case 2:
          state = '已拒绝'
          break
      }
      return state
    },
    onReview(data) {
      this.reviewData = data
      this.visible = true
    },
    refreshPage() {
      this.init()
    },
    search() {
      if (typeof this.query.createTime == 'object') {
        if (this.query.createTime) {
          this.query.applyStartTime = this.query.createTime[0] + ' 00:00:00'
          this.query.applyEndTime = this.query.createTime[1] + ' 23:59:59'
          delete this.query.createTime
        } else {
          this.query.applyStartTime = ''
          this.query.applyEndTime = ''
        }
      }
      this.searchChange()
    }
  }
}
</script>

<style lang="scss" scoped>
// .app-container {
//   display: flex;
//   flex-direction: column;
// }
</style>
