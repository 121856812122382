<template>
  <el-dialog title="提交审核" :visible.sync="visible" width="840px" class="dialog-warpper" :before-close="handleClose">
    <div class="title">提现信息</div>
    <div class="flex-warpper">
      <div class="flex-item">
        分销商:
        <span class="normal">{{ reviewData.nickName }}</span>
      </div>
      <div class="flex-item">
        当前余额:
        <span class="emphasize">{{ reviewData.remainingAmount }}</span>
      </div>
      <div class="flex-item">
        提现金额:
        <span class="emphasize">{{ reviewData.amount }}</span>
      </div>
      <div class="flex-item">
        提现手续费:
        <span class="emphasize">{{ reviewData.withdrawalFee }}</span>
      </div>
      <div class="flex-item">
        实际提现金额：
        <span class="emphasize">{{ reviewData.realAmount }}</span>
      </div>
      <div class="flex-item">
        转账方式:
        <span class="normal">{{ reviewData.paymentType == 1 ? '支付宝' : '微信' }}</span>
      </div>
      <div class="flex-item">
        提现申请时间:
        <span class="normal">{{ parseTime(reviewData.applyTime) }}</span>
      </div>
    </div>
    <div class="title">审核</div>
    <el-form ref="form" label-width="90px">
      <el-form-item label="是否通过：">
        <el-radio-group v-model="radio">
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="2">拒绝</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="textarea"
          disabled
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onBack">返回</el-button>
      <el-button :loading="loading" type="primary" @click="onConfirm">提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { parseTime } from '@/utils'
import { checkWithdraw } from '@/api/withdrawApi'
export default {
  data() {
    return {
      loading: false,
      textarea: '',
      radio: 1
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.reviewData.remark) {
          this.textarea = this.reviewData.remark
        }
        if ([0, 1].includes(this.reviewData.approveStatus)) {
          this.radio = 1
        } else {
          this.radio = 2
        }
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    reviewData: {
      type: Object,
      default: () => ({})
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    parseTime,
    handleClose() {
      this.$emit('update:visible', false)
      this.textarea = ''
      this.$refs['form'].clearValidate()
    },
    onSuccessMessage() {
      this.handleClose()
      this.sup_this.init()
      this.$message.success('保存成功')
    },
    async onConfirm() {
      this.loading = true
      //成功回调中调用this.handleClose,并给出提示
      try {
        const data = {
          id: this.reviewData.id,
          approveStatus: this.radio,
          approveOpinion: this.textarea
        }
        const { code } = await checkWithdraw(data)
        if (code == 0) {
          this.onSuccessMessage()
          this.loading = false
        }
      } catch (error) {
        this.loading = false
      }
    },
    onBack() {
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  font-size: 14px;
  .title {
    color: #595961;
    margin-bottom: 30px;
    font-weight: 500;
  }
  .flex-warpper {
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    .flex-item {
      flex: 1;
      width: 33.3%;
      min-width: 33.3%; // 加入这两个后每个item的宽度就生效了
      max-width: 33.3%;
      color: #969799;
      margin-bottom: 36px;
      .normal {
        color: #2d2d2d;
      }
      .emphasize {
        color: #f54a45;
      }
    }
  }
  ::v-deep {
    .el-form-item__label {
      color: #969799;
    }

    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
</style>
