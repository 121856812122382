import { render, staticRenderFns } from "./reviewDialog.vue?vue&type=template&id=642c4b05&scoped=true&"
import script from "./reviewDialog.vue?vue&type=script&lang=js&"
export * from "./reviewDialog.vue?vue&type=script&lang=js&"
import style0 from "./reviewDialog.vue?vue&type=style&index=0&id=642c4b05&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642c4b05",
  null
  
)

export default component.exports